import { useParams} from "react-router-dom";
import Difuntos from '../components/main_sepultura.js'

const GetData = ()=>{
    const { difunto_qr_id } = useParams();
    return difunto_qr_id
}

const MainWeb = () =>{
    return (
        <div id="mainweb">
            <Difuntos qr={GetData()} />
        </div>
    )
}

export default MainWeb