const MainLanding = () =>{
    return (
        <div id="landing">
            <div className="wellcome-box">

            </div>
        </div>
    )
}

export default MainLanding