import axios from 'axios'
import {useState, useEffect} from 'react'

export const GetDataFromQR = async (url) =>{
    const MyComponent = await axios.get(url)
    .then((response) =>{
        return response.data
    }).catch((error)=>{
        console.log("ERROR: ", error)
        return null
    }).finally(()=>{
        console.log("LISTO")
    })
    return MyComponent
    
}