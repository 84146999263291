import React from 'react';
import ReactDOM from 'react-dom/client';
import ErrorPage from './pages/error-page.jsx'
import MainPage from './pages/main.jsx'
import MainLanding from './pages/landing.jsx'
import "./css/back.css"
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  {
    path:"/",
    element: <MainLanding />,
    errorElement: <ErrorPage />
  },
  {
    path:"/i/:difunto_qr_id",
    element: <MainPage />,
    errorElement: <ErrorPage />
  }
])

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)
