import {GetDataFromQR} from '../api/Rest-n-link.js'
import React, {useState, useEffect} from 'react'
import Boxes from './Main_Sepultura_Boxs.js'
import { v4 as uuidv4 } from 'uuid';

import Modal from './Modal_pesame.js'

function GetDifuntos(props){
    //const URL = "http://127.0.0.1:3100/api/v1/sepultura/"
    const URL = "https://api.restinlink.ovh/api/v1/sepultura/"
    const [loading, setLoading] = useState(true)
    const [difuntosData, setDifuntos] = useState(null)
    const [isPesameModal, setPesemaModal] = useState(false)

    useEffect(()=>{
        const loadSepultura = async () =>{
            const sepulturaData = await GetDataFromQR(URL + props.qr)
            if(sepulturaData != null){
                setDifuntos(sepulturaData.difuntos)
            }
            setLoading(false)
        }
        loadSepultura();
    }, [])

    if(loading == false){
        if(difuntosData == null){
            return ""
        }
    }

    return (
            <>
            <div key="contenedorDifuntos" className='contenedor-difuntos'>
                {loading ? (<p>Cargando</p>) : (
                    difuntosData.map((e) => {
                        return <Boxes key={uuidv4()} data={e} dataModal={setPesemaModal} />;
                    })
                )}
            </div>
            <Modal open = {isPesameModal} close = {()=> setPesemaModal(false)}  key="mdlCondolencia">
                asdasdasdadadas
            </Modal></>
    )

}

export default GetDifuntos