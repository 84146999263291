import { v4 as uuidv4 } from 'uuid';
const Boxes = (e) =>{
    const {data} = e
    const {dataModal} = e

    const Medios = data.medios
    const Paloma = "https://img.freepik.com/vector-gratis/ilustracion-contorno-paloma-dibujada-mano_23-2149255735.jpg?w=996&t=st=1714526914~exp=1714527514~hmac=6866962a57c81e5e330213509f92acdb4c87c7e2083481acd5ca0ea2b7687b36"
    let perfil = ""

    if(Medios != null){
        Medios.map((m)=>{
            if(m.media_tipo.id == 6 || m.media_tipo.id == 12 ){
                perfil = m.url
                return
            }
        })
    }

    console.log(dataModal)

    return (
        <>
            <div className="box">
                <div className='title_box'>
                    <h1>{data.nombres}</h1>
                </div>
                <div className='profile_image'>
                    <img src={(perfil != "" ? perfil : Paloma)} />
                </div>
                <div className='personal_data'>
                    <p>Nombre: {data.nombres}</p>
                    <p>Apellido: {data.apellidos}</p>
                    <p>Nacimiento: {data.nacimiento}</p>
                    <p>Partida: {data.partida}</p>
                </div>

                <div className='stat'>
                    <div id='s_1'><a href=''>Condolencias</a></div>
                    <div id='s_2'><a href=''>Multimedia</a></div>
                    <div id='s_3'><a href=''>Biografía</a></div>
                </div>
            </div>
            
        </>
    )
}

export default Boxes